import { Page } from '~/cms/front/core/pages/Page'
import component from './ReturnPage.vue'
import { MetaPixelService } from '~/website/front/core/services/MetaPixelService'

class ReturnPage extends Page {
	static alias = 'return'
	showCheckoutProgressBar = true
	component = component

	trustedData

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('return-page')
		const service = await this.app.getService('rext')
		if (process.client) {
			setTimeout(() => {
				const facebookPixelData = JSON.parse(window.localStorage.getItem('facebookPixelData'))
				MetaPixelService.emitEvent('Purchase', facebookPixelData)
				window.localStorage.removeItem('facebookPixelData')
			}, 500)
		}
		this.trustedData = JSON.parse(this.query.trustedShopsData)
		this.klaviyoClientData = JSON.parse(this.query.klaviyoClientData)

		await service.klaviyoCreateProfile(this.klaviyoClientData)
	}

	async afterRedirect () {
		if (!process.client) return false
		const id = this.query.otfd

		const data = await this.app.getService('rext').fetchOTFD(id)
		if (!data.error) this.app._emit('purchase', data)
	}
}

export { ReturnPage }
