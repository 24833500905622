<template>
	<div class="title-wrapper">
		<span class="line additional" v-if="alignCenter"></span>
		<base-font variant="medium-header" marginReset :tag="tag" weight="regular" class="title">
			<slot/>
		</base-font>
		<span class="line"></span>
		<base-link class="edit-link" :to="alias" :params="{id: $app.page.route.params.id}" type="primary" v-if="withEdit">
			<base-svg name="edit"/>
			<span class="edit-text">Edytuj</span>
		</base-link>
	</div>
</template>
<script>
export default {
	props: {
		alignCenter: {
			type: Boolean,
			default: false
		},
		withEdit: {
			type: Boolean,
			default: false
		},
		alias: {
			type: String,
			default: 'checkout'
		},
		tag: {
			type: String,
			default: 'h3'
		}
	}
}
</script>
<style lang="scss" scoped>
	.title-wrapper {
		display: flex;
        align-items: center;
		margin-block: 2rem;

		.line {
			content: '';
			flex: 1;
			margin: 4px 0 0 20px;
			height: 1px;
			background-color: $gray-600;

			&.additional {
				margin: 4px 20px 0 0;
			}
		}
		&:deep(.title) {
			line-height: 1;
			font-size: 18px;
			font-family: $secondary-font;
			@include media-breakpoint-up(md) {
				font-size: 22px;
			}
			@include media-breakpoint-up(xl) {
				font-size: 28px;
			}
		}
		.edit-link {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			margin-left: 20px;
			.edit-text {
				font-size: 1.2rem;
			}
			&:hover {
				.svg-icon path {
					fill: $gold;
				}
			}
			.svg-icon {
				width: 20px;
				height: 20px;
				margin-bottom: 5px;
			}
		}
	}
</style>
