<template>
	<base-container>
		<div id="trustedShopsCheckout" style="display: none;">
			<span id="tsCheckoutOrderNr">{{trustedShops.id}}</span>
			<span id="tsCheckoutBuyerEmail">{{trustedShops.email}}</span>
			<span id="tsCheckoutOrderAmount">{{trustedShops.amount}}</span>
			<span id="tsCheckoutOrderCurrency">PLN</span>
			<span id="tsCheckoutOrderPaymentType">{{trustedShops.paymentMethod}}</span>
			<span id="tsCheckoutOrderEstDeliveryDate">{{trustedShops.deliveryDate}}</span>
		</div>
		<script type="text/javascript">
			dispatchEvent(new Event('load'));
		</script>
		<base-row>
			<template v-if="error">
				<base-col col="12">
					<div class="wrapper">
						<divider-heading alignCenter>{{value.failure_title}}</divider-heading>
						<cms-text
							class="info-wrapper"
							:props="{size: 'xs', tag:'div'}"
							value="return_page.failure_text"
						/>
						<base-button to="product-list" class="back-btn" type="white">{{translate.button}}</base-button>
					</div>
				</base-col>
			</template>
			<template v-else>
				<base-col col="12">
					<div class="wrapper">
						<divider-heading alignCenter>{{value.success_title}}</divider-heading>
						<cms-text
							class="info-wrapper"
							:props="{size: 'xs', tag:'div'}"
							value="return_page.success_text"
						/>
						<base-button to="homepage" class="back-btn" type="white">{{translate.button}}</base-button>
					</div>
				</base-col>
			</template>
		</base-row>
	</base-container>
</template>
<script>
import DividerHeading from '~/website/front/components/molecules/DividerHeading'
export default {
	components: { DividerHeading },
	computed: {
		error () {
			return this.$app.page.route.query.error
		},
		translate () {
			return this.$app.translator.get('return-page')
		},
		value () {
			return this.$app.page.value.return_page
		},
		trustedShops () {
			return JSON.parse(this.$app.page.query.trustedShopsData)
		}
	},
	async mounted () {
		const service = await this.$app.getService('rext')
		if (this.$app.authorization.isAuthorizated) {
			await service.klaviyoCreateOrderConfirmEvent({ email: this.$app.authorization.client.user.email })
		} else {
			await service.klaviyoCreateOrderConfirmEvent({ email: this.trustedShops.email })
		}
	}
}
</script>
<style lang="scss" scoped>
	.wrapper {
		margin: 3rem 0;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		@include media-breakpoint-up (lg) {
			margin: 7rem 0;
		}
		.info-wrapper {
			margin: 3rem auto;
			@include media-breakpoint-up(lg) {
				width: 40%;
			}
		}

		:deep(.back-btn) {
			margin: 3rem auto 3rem auto;
			max-width: 330px;
			justify-content: center;

			@include media-breakpoint-up(md) {
				margin: 3rem auto 0 auto;
			}
		}
	}
</style>
